import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      wordpressWpMedia(slug: {eq: "instagram"}) {
        localFile {
          childImageSharp {
            fixed(width: 32) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      wordpressPage(slug: {eq: "home"}) {
        acf {
          contact_details
        }
      }
    }
  `)
  return (
    <div className="bg-gold p-6 flex flex-col-reverse sm:flex-row">

      <div className="flex items-center">
        <a href="https://www.instagram.com/inthreadiblequilting/">
          <Img fixed={data.wordpressWpMedia.localFile.childImageSharp.fixed} />
          <p className="ml-2 text-sm leading-4 inline-block font-thin">
            Follow me on Instagram<br />
            <span className="font-semibold">@inthreadiblequilting</span>
          </p>
        </a>
      </div>

      <div className="flex-grow my-4"></div>

      <div
        className="text-right leading-5"
        dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.contact_details }}
      />

    </div>
  )
}

export default Footer
