import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

function Navbar () {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(sort: { fields: menu_order }) {
        edges {
          node {
            title
            slug
          }
        }
      }
      wordpressWpMedia(slug: {eq: "logo"}) {
        localFile {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `)
  const path = typeof window !== 'undefined' ? window.location.pathname : ''
  return (
    <nav className="nav bg-white flex flex-col sm:flex-row">
      <Link
        className="flex justify-center sm:px-4 md:px-6 lg:px-8 lg:pt-4"
        to="/"
      >
        <Img fixed={data.wordpressWpMedia.localFile.childImageSharp.fixed} />
      </Link>
      {
        data.allWordpressPage.edges.map(({ node: page }) => {
          let linkPath
          if (page.slug === 'home') {
            linkPath = '/'
          } else {
            linkPath = '/' + page.slug
          }
          const isActive = path === linkPath ? 'active' : ''
          return (
            <Link
              className={`flex flex-grow justify-center items-center my-1 ${isActive}`}
              to={linkPath}
              key={linkPath}
            >
              <span className="uppercase text-sm border-b-2 border-white">
                {page.title}
              </span>
            </Link>
          )
        })
      }
    </nav>
  )
}

export default Navbar
